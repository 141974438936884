import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { Chip } from 'design-system';

interface StepTagsProps {
  stepIndex: number;
  setStepIndex: (stepIndex: number) => void;
}

const homeSteps = ['사전준비', '학과시험', '장내기능', '도로주행', '운전연수'] as const;

export const StepTags = ({ stepIndex, setStepIndex }: StepTagsProps) => {
  const [ref, inView, entry] = useInView();
  const isStepTagsFullyScrolled = !inView && entry;

  const throwIndex = (e: number) => {
    setStepIndex(e);
  };

  useEffect(() => {
    if (isStepTagsFullyScrolled) {
      entry.target.scrollIntoView();
    }
  }, [stepIndex]);

  return (
    <>
      <div className="bg-gray-150 h-full">
        <h3 className="text-new-Section-Title">운전면허, 자세히 알아봐요</h3>
      </div>
      <div className="h-[0.1px] w-full" ref={ref} />
      <div className="bg-gray-150 sticky top-0 z-10 -mx-16 flex overflow-auto px-16 py-12">
        {homeSteps.map((data: string, index: number) => {
          return (
            <div key={index}>
              <div onClick={() => throwIndex(index)}>
                <Chip label={data} styles={stepIndex === index ? 'filled' : 'noline'} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
