import { useEffect, useState } from 'react';

import { useScrollHistory } from '@hooks';

import { GuideSlider, StepTags } from './components';

const getStepIndex = (): number => {
  const savedStepIndex = typeof window !== 'undefined' ? sessionStorage.getItem('stepIndex') : null;
  return savedStepIndex !== null ? Number(savedStepIndex) : 0;
};

export const HomeGuide = () => {
  const [stepIndex, setStepIndex] = useState<number>(0);

  useEffect(() => {
    // client에서 실행
    setStepIndex(getStepIndex());
  }, []);

  // stepIndex가 변경될 때마다 sessionStorage에 저장
  useEffect(() => {
    stepIndex !== null && sessionStorage.setItem('stepIndex', stepIndex.toString());
  }, [stepIndex]);

  // 홈가이드 내 스크롤 히스토리
  useScrollHistory({ setStorageName: 'scrollPositionInHome' });

  return (
    <div className="px-16">
      <StepTags stepIndex={stepIndex} setStepIndex={setStepIndex} />
      <GuideSlider stepIndex={stepIndex} setStepIndex={setStepIndex} />
    </div>
  );
};
