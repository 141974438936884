import { useRouter } from 'next/router';

import { InnerBanner } from '@components';

interface ContentsInnerBannerProps {
  title: string;
  subTitle: string;
  src: string;
  path: string;
}

export const ContentsInnerBanner = ({ title, subTitle, src, path }: ContentsInnerBannerProps) => {
  const router = useRouter();
  return (
    <InnerBanner
      color='white'
      title={title}
      subTitle={subTitle}
      image={src}
      arrow={false}
      onClick={() => {
        router.push(`${path}`);
      }}
    />
  );
};
