import classNames from 'classnames';
import { NewIcon } from 'design-system';
import { type HTMLMotionProps, motion } from 'framer-motion';
import Image from 'next/image';

interface InnerBannerProps extends HTMLMotionProps<'div'> {
  color: 'white' | 'black' | 'DTYellow' | 'DTGray';
  title: string;
  subTitle: string;
  image: string;
  arrow: boolean;
  disabled?: boolean;
}

export const InnerBanner = ({
  color,
  title,
  subTitle,
  image,
  arrow,
  disabled,
  className,
  ...props
}: InnerBannerProps) => (
  <motion.div
    role="button"
    className={classNames(
      'flex cursor-pointer items-center justify-between rounded-[20px] px-24 py-16',
      color === 'white' && 'bg-white',
      color === 'black' && 'bg-black',
      color === 'DTYellow' && 'bg-DTYellow-400',
      color === 'DTGray' && 'bg-gray-150',
      className,
    )}
    whileTap={{ scale: 0.97 }}
    {...props}
  >
    <div>
      <p className={classNames('text-SubTitle', disabled && 'text-gray-400')}>{title}</p>
      <p className={classNames('text-Body7', disabled ? 'text-gray-400' : 'text-gray-600')}>
        {subTitle}
      </p>
    </div>
    <div className="flex items-center gap-16">
      <Image
        src={image}
        width={56}
        height={56}
        alt="innerBanner"
        sizes="(max-width: 56px) 100vw"
        className={classNames(disabled && 'opacity-50')}
      />
      {arrow && (
        <NewIcon
          icon="arrowright-outlined"
          size={16}
          className={classNames('fill-new-gray-400', disabled && 'opacity-50')}
        />
      )}
    </div>
  </motion.div>
);
