import { NewIcon } from 'design-system';
import { useRouter } from 'next/router';

interface ContentInStepsProps {
  title: string;
  body: string;
  icon: string;
  id: string;
  sideBanner?: React.ReactElement;
}

export const ContentInSteps = ({ title, body, icon, id }: ContentInStepsProps) => {
  const router = useRouter();

  return (
    <div
      className="px-18 bg-new-gray-50 flex h-full w-full cursor-pointer items-center justify-between rounded-lg py-12"
      onClick={() => {
        const path = `/${id}`;

        router.push(path);
      }}
    >
      <div className="flex">
        <div className="ml-6">
          <p className="text-new-Body1-bold text-gray-800">{title}</p>
          <p className="text-new-Body2-medium text-new-gray-500">{body}</p>
        </div>
      </div>
      <NewIcon icon="arrowright-outlined" size={20} className="fill-new-gray-400" />
    </div>
  );
};
