import { ContentInSteps, ContentsWithDots } from '../components';

type GuideStep = Content[];

type Content = Level | Banner | SideBanner;

interface Level {
  type: 'level';
  level: number;
  title: string;
  body: {
    taskTitle: string;
    taskBody: any;
  }[];
}

interface Banner {
  type: 'banner';
  title: string;
  sub: string;
  href: string;
  img: string;
}

interface SideBanner {
  type: 'sideBanner';
  sideBanner: React.ReactElement;
}

const 사전준비: GuideStep = [
  {
    type: 'banner',
    title: '모든 면허 종류 알아보기',
    sub: '나는 어떤 면허를 따면 좋을까?',
    href: '/blog/vVlwu00swAvSN45U7gYS',
    img: '/guide/all-car.png',
  },
  {
    type: 'level',
    level: 1,
    title: '학원 등록',
    body: [
      {
        taskTitle: '학원 예약하기',
        taskBody: (
          <ContentInSteps
            title="운전선생으로 최저가 예약하기"
            body="운전학원을 비교하고 예약할 수 있어요."
            icon="/test/book.png"
            id="explore"
          />
        ),
      },
      {
        taskTitle: '학원 가기 전, 준비물 챙기기',
        taskBody: (
          <ContentsWithDots
            contents={[
              '신분증',
              '수강료',
              <>
                학과시험 합격증{' '}
                <span className="text-new-Body2-medium text-new-gray-400">(합격자 한정)</span>
              </>,
            ]}
          />
        ),
      },
    ],
  },
];

const 학과시험: GuideStep = [
  {
    type: 'level',
    level: 2,
    title: '학원 도착',
    body: [
      {
        taskTitle: '데스크에서 수업 안내 받기',
        taskBody: '',
      },
      {
        taskTitle: '학과수업 듣기',
        taskBody: (
          <ContentInSteps
            title="학과수업이란?"
            body="학과수업에서는 무엇을 배울까요?"
            icon="/test/board.png"
            id="/blog/5VF8OJ2keouaOs3rywHi"
          />
        ),
      },
    ],
  },
  {
    type: 'banner',
    title: '학과시험',
    sub: '학과시험이란 무엇일까?',
    href: '/blog/xjxRqLOpPxiGKodCPhm3',
    img: '/guide/first-test.png',
  },
  {
    type: 'level',
    level: 3,
    title: '학과(필기)시험',
    body: [
      {
        taskTitle: '시험 공부하기',
        taskBody: (
          <ContentInSteps
            title="운전선생으로 시험 공부하기"
            body="공부만 해도 다양한 혜택을 드려요."
            icon="/test/test.png"
            id="test"
          />
        ),
      },
      {
        taskTitle: '시험 준비물 챙기기',
        taskBody: (
          <ContentsWithDots
            contents={[
              '신분증',
              '현금 10,000원',
              <>
                사진 3장 <span className="text-gray-400">(6개월 이내 촬영, 여권 사진 크기)</span>
              </>,
              <>
                신체검사 결과지 <span className="text-gray-400">(시험장별로 상이)</span>
              </>,
            ]}
          />
        ),
      },
      {
        taskTitle: '시험 날짜 잡기',
        taskBody: '',
      },
      {
        taskTitle: '시험장 가서 학과시험 보기',
        taskBody: '',
      },
    ],
  },
];

const 장내기능: GuideStep = [
  {
    type: 'banner',
    title: '장내기능 완벽 가이드',
    sub: '이거 하나면 장내기능시험 준비 끝!',
    href: '/guide/driver-test',
    img: '/guide/driver-test.png',
  },
  {
    type: 'level',
    level: 4,
    title: '장내기능',
    body: [
      {
        taskTitle: '수업 & 시험 일정 잡기',
        taskBody: '',
      },
      {
        taskTitle: '장내기능수업 듣기',
        taskBody: (
          <ContentInSteps
            title="장내기능시험 꿀팁"
            body="운전선생이 직접 선별했어요!"
            icon="/test/car.png"
            id="/blog/RpEBHSmoUknRDnpX4t0t"
          />
        ),
      },
      {
        taskTitle: '장내기능시험 보기',
        taskBody: '',
      },
    ],
  },
];

const 도로주행: GuideStep = [
  {
    type: 'banner',
    title: '도로주행',
    sub: '도로주행이란 무엇일까?',
    href: '/blog/DsalAOSpSCypk5QXMzMa',
    img: '/guide/last-test.png',
  },
  {
    type: 'level',
    level: 5,
    title: '도로주행',
    body: [
      {
        taskTitle: '수업 & 시험 일정 잡기',
        taskBody: '',
      },
      {
        taskTitle: '도로주행수업 듣기',
        taskBody: (
          <ContentInSteps
            title="도로주행시험 꿀팁"
            body="운전선생이 직접 선별했어요!"
            icon="/test/car.png"
            id="/blog/ZA8oNgQwbjeFWqnb0bDQ"
          />
        ),
      },
      {
        taskTitle: '도로주행시험 보기',
        taskBody: '',
      },
    ],
  },
];

const 운전연수: GuideStep = [
  {
    type: 'banner',
    title: '운전선생의 방문 운전연수',
    sub: '합법적인 연수를 받아보세요!',
    href: '/training',
    img: '/training/training-banner.png',
  },
  {
    type: 'level',
    level: 6,
    title: '운전연수',
    body: [
      {
        taskTitle: '운전연수 신청하기',
        taskBody: '',
      },
      {
        taskTitle: '운전연수 받기',
        taskBody: <ContentsWithDots contents={['운전선생의 합법적인 운전연수']} />,
      },
    ],
  },
];

// const 면허취득: GuideStep = [
//   {
//     type: 'sideBanner',
//     sideBanner: (
//       <>
//         <div className='text-Title2 absolute pl-24 pt-28 font-bold text-gray-800'>
//           <p>축하해요! 받을 수 있는</p>
//           <p>혜택과 꿀팁들을 모아봤어요.</p>
//         </div>
//         <div className='mb-10 mt-[-10px] flex h-full w-full items-center justify-center rounded-[24px] bg-white'>
//           <Image
//             src='/guide/license-card-blank.png'
//             width={358}
//             height={235}
//             alt='면허취득-card'
//             sizes='(max-width: 358px) 100vw'
//           />
//         </div>
//       </>
//     ),
//   },
//   {
//     type: 'sideBanner',
//     sideBanner: (
//       <InnerBanner
//         color='white'
//         title='킥보드 할인권'
//         subTitle='24년 1월 오픈 예정'
//         image='/test/kickboard.png'
//         arrow
//         disabled
//       />
//     ),
//   },
//   {
//     type: 'sideBanner',
//     sideBanner: (
//       <InnerBanner
//         className='mb-10'
//         color='white'
//         title='렌터카 할인권'
//         subTitle='24년 1월 오픈 예정'
//         image='/test/car.png'
//         arrow
//         disabled
//       />
//     ),
//   },
//   {
//     type: 'banner',
//     title: '모바일 운전면허증 발급받는 법',
//     sub: '이제 핸드폰에서 확인할 수 있어요.',
//     href: '/blog/oAGiHR4W9ZfW3YPWlewK',
//     img: '/guide/content5.png',
//   },
// ];

export const GuideSteps: GuideStep[] = [사전준비, 학과시험, 장내기능, 도로주행, 운전연수];
