import { ReactElement } from 'react';

export const ContentsWithDots = ({ contents }: { contents: (string | ReactElement)[] }) => {
  return (
    <div className='flex flex-col gap-3 pl-10'>
      {contents.map((content, index) => (
        <li key={index} className='text-new-Body2-medium text-new-gray-600'>
          {content}
        </li>
      ))}
    </div>
  );
};
